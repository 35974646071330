
import Testimonials from '../services/TestimonialsService'
import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination]);


const OurTestimonials = () => {

    const [content, setContent] = useState("")


    const swiperParams = {
        slidesPerView: 1,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
       
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            992: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        },
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        Testimonials.testimonials()
            .then((response) => {
                console.log(response)
                setData(response.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            }).catch((error) => {
                console.log(error)
            })
    }, []);

    return (

        <section id="testimonials" className="testimonials ">
            <div className="container">

                {/* <div className="testimonials-header">
                    <h2 data-aos="fade-in" className='text-center'>Our Testimonials</h2>
                </div> */}

                <div className='section-title text-center mt-5'>
                    <span className='our-product-header text-center'>Our Testimonials</span>
                </div><br/>

                <div className="row justify-content-center ">
                    <div className="col-lg-8 d-flex align-items-stretch  mx-auto ">
                        <Swiper {...swiperParams} >
                            {data.map(values => (
                                <SwiperSlide>
                                    <div className="testimonial-wrap justify-content-center">
                                        <div className="testimonial-item text-center">
                                            <p className="quote-text">
                                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                               {values.comment}
                                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                            </p>
                                            <h3>{values.AuthorName}</h3>
                                            <h4>{values.designation}</h4>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTestimonials