import React, { useEffect, useState } from 'react'
import OrganizationService from '../services/OrganizationService'
function AboutUs() {

    const [data, setData] = useState();

    const [content, setContent] = useState("");


    useEffect(() => {

        try {
            OrganizationService.organizationService()
                .then((response) => {
                    response.data.map((obj) => {
                        setData(obj.description)
                    })
                },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        setContent(_content);
                    }
                )
                .catch((error) => {
                    console.log(error)
                })

        } catch (error) {
            console.error("error occured in organization service ")
        }

        console.error(content)
    }, [])

    

    useEffect(() => {
        window.scroll(0, 0)

    }, []);



    return (
        <section className='about-us' id='about-us'>
            <div className='container mt-3 mb-3'>
                <div className='row  justify-content-center'>
                    <div className='col-md-8 text-center '>
                        <img src="./images/orglogo.jpeg" alt='...' width="340" height="100" className='img-flude' />
                    </div>
                    <div className='col-md-12 mt-3'>
                        <p className='description'>{data}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs