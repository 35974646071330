import React from 'react'


import CurrentOpening from './CurrentOpening';
import Footer from './Footer';

function CareerPage() {

  return (
    <>
    <div className='career-page'>
      <CurrentOpening />
     
    </div>
    <Footer />
    </>
    
  )
}

export default CareerPage