import React, { useState, useEffect } from "react";

import HomeSliderservice from "../services/HomeSlider";


import pic1 from '../Assests/images/blockchain-development.png'

import pic2 from '../Assests/images/ai-development.png'

import pic3 from '../Assests/images/web-development.png'


function HomeSlider() {

    const [data, setData] = useState([]);

    const [content, setContent] = useState("")

    useEffect(() => {
        HomeSliderservice.getHomeSlider()
            .then(res => {
                setData(res.data)
                console.log(res)
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content);

                })
            .catch(err => (err))
    }, [])


    // console.log(data)




    return (

        <div className="container-flude ">
            {/* <video src={videos1} autoPlay loop className="d-block w-100 homepage-bg" style={{opacity:'0.9'}}/> */}
            
            <div id="carouselExampleCaptions" className="carousel slide w-100 h-100 homepage-fg" data-bs-ride="carousel" >
                <div className="carousel-indicators" >
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>


                <div className="carousel-inner slider-inner" >

                    {data.map((slider, index) => {

                        if (index === 0) {
                            return (
                                <>
                                    <div className="carousel-item active">
                                        <img src={"./images/product/" + slider.imageUrl} className="d-block w-100" alt="..." />
                                        <div className="col-lg-12" >
                                            <div className="carousel-caption d-none d-md-block " >
                                                <h5 className='drop-in' >{slider.title}</h5>
                                                <p className='drop-in-2 caption-slider' >{slider.subTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>

                                    <div className="carousel-item " >
                                        <img src={"./images/product/" + slider.imageUrl} className="d-block w-100" alt="..." />
                                        <div className="carousel-caption d-none d-md-block ">
                                            <h5 className='drop-in' >{slider.title}</h5>
                                            <p className='drop-in-2 caption-slider' >{slider.subTitle}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })}

                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>

                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

            </div>
            <div className="h-wrapper ">
                <div className="h-container">
                    <div className="hero-container">
                        <div className="text">
                            <h1> "Seamless Data Migration, </h1>
                            <h1>Expert Staffing & HRMS Excellence</h1>
                            <h1>Your Tech Transformation Partners"</h1>
                        </div>
                        <div className="image-hero">
                            <img src={pic3} alt="..." height='600' />
                        </div>
                    </div>
                </div>
            </div> 

        </div>



    );
}

export default HomeSlider;