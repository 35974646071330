import axios from "axios";




const organizationContactDetails = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/contact/get")
    } catch (error) {
        return [];
    }
}

export default {
    organizationContactDetails,
}