import React, { useState, useEffect } from "react";
import { useContext } from "react";
import ProductService from "../services/ProductService";
import { createSearchParams, useNavigate } from "react-router-dom";
import { eventWrapper } from "@testing-library/user-event/dist/utils";

function ProductInfo() {

    const history = useNavigate();

    const [data, setData] = useState([])

    const [content, setContent] = useState("")

    useEffect(() => {
        ProductService.OurProducts()
            .then(res => {
                setData(res.data)
                console.log(res.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            })
            .catch(err => (err))
    }, [])

    console.log(data)

    const [temp, setTemp] = useState()

    const [temp1, setTemp1] = useState()

    const onNavigate1 = (event) => {
        setTemp1(event)
    }

    const onNavigate = (event) => {

        setTemp(event)



    }

    return (
        <section id="product-list" className="product-list" data-aos="fade-up">
            <div className="container ">
                {/* 
                <div className="section-header">
                    <h2>Our Products</h2>
                
                </div> */}

                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Our Products</span>
                </div>

                {data.map((product, index) => {

                    if (index % 2 === 0) {

                        return (
                            <div key={product.id} id={`product${product.id}`} className="row product-table-card content mt-4">
                                <div className="col-md-6 " data-aos="fade-right" data-aos-delay="100">
                                    <img src={"./images/product/" + product.imageUrl} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-6 p-3" data-aos="fade-left" data-aos-delay="100">
                                    <a className="title">{product.name}</a>
                                    <p className="description">{product.shortDescription}</p>
                                    <button type="button" className="btn btn-primary  m-2" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1" onClick={() => onNavigate(product.id)}>Read More</button>
                                </div>
                                <div className="collapse " id="collapseExample1">
                                    <div className="row mt-3 justify-content-center">

                                        {product.FeatureLists.map((fea) => {

                                            {
                                                if (product.id == temp) {

                                                    // return obj.featureList.map((fea) => {
                                                    return <>
                                                        <div key={fea.id} id="diamond-narrow" className="col-md-6 col-lg-4 wow bounceInUp mt-3 mb-5" data-aos="zoom-in" data-aos-delay="100">
                                                            <div className="feature-card">
                                                                <p className="card-title">{fea.description}</p>
                                                                <p className="text-start card-text">{fea.aboutDescription}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    // })
                                                }
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    return (<>
                        <div id={`product${product.id}`} className="product-table-card1 mt-4 p-3">
                            <div key={product.id} className="row content  ">
                                <div className="col-md-6 order-1 order-md-2 d-flex justify-content-end " data-aos="fade-left">
                                    <img src={"./images/product/" + product.imageUrl} className="img-fluid " alt="" />
                                </div>
                                <div className="col-md-6 order-2 order-md-1 p-3" data-aos="fade-right">
                                    <a className="title">{product.name}</a>
                                    <p className="description">{product.shortDescription}</p>
                                    <button type="button" className="btn btn-primary m-2" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={() => onNavigate1(product.id)} >Read More</button>
                                </div>

                            </div>
                            <div className="collapse " id="collapseExample">
                                <div className="row mt-3 justify-content-center">

                                    {product.FeatureLists.map((fea) => {
                                        {
                                            if (product.id == temp1) {
                                                // return obj.featureList.map((fea) => {
                                                return <>
                                                    
                                                    <div key={fea.id}  className="order-md-0 col-md-12 col-lg-4 wow bounceInUp mt-3 mb-4 order-1" data-aos="zoom-in" data-aos-delay="100">
                                                        <div className="feature-card">
                                                            <p className="card-title">{fea.description}</p>
                                                            <p className="text-start card-text">{fea.aboutDescription}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div id="outer-circle">
                                                        <div id="inner-circle">

                                                        </div>
                                                    </div> */}

                                                </>
                                                // })
                                            }
                                        }
                                    })}
                                </div>
                            </div>

                        </div>

                    </>
                    )
                }
                )}
            </div>
        </section>
    );
}

export default ProductInfo;


// onClick={() => onNavigate(product.id)}

// onClick={() => onNavigate(product.id)}