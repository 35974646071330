import axios from "axios";




const getHomeSlider = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/slider/get")
    } catch (error) {
        return []
    }
}


export default { getHomeSlider }