import axios from "axios";



const OurProducts = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/product/get");
    } catch (error) {
        return [];
    }
}

const GetSingleProduct = (id) => {
    try {
        return axios.get(`https://www.api.redberyltech.com/api/product/${id}`)
    } catch (error) {
        return []
    }
}


export default {
    OurProducts,
    GetSingleProduct,
}