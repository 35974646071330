import React, { useState, useEffect } from 'react'
import AboutUsService from "../services/AboutUsService";

function OurValues() {

    const [data, setData] = useState([]);

    const [content, setContent] = useState("")

    useEffect(() => {
        AboutUsService.aboutusService()
            .then((response) => {
                console.log(response)
                setData(response.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            }).catch((error) => {
                console.log(error)
            })
    }, []);


    return (
        <div className=" values" id='values'>
            <div className=" mt-4">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    {data.map(val => (
                        <div className="row ">
                            <div className="col-md-6 d-flex justify-content-center ">
                                <img src={val.valueUrl} className="circle-border" alt="..." height="200px" width='210px' />
                            </div>
                            <div className="col-md-6 mt-4">
                                <p className="title">Our Values</p>
                                <p className="description">{val.values}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurValues;