import React from 'react'
import { useEffect } from 'react'

export default function Privacypolicy() {
    
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="pvcpolcy container">

            <div className="section-header text-white mt-3">
                <h2>Privacy & Policy</h2>
            </div>
            <h4 className="mb-3 mt-3 title">1. Privacy Policy?</h4>
            <p className="description">RedBeryl Privacy Policy governs the security &amp; use of personal data of users when they use the website.  While using the website “RedBeryl” may ask to provide personal information for internal use of communication and identifying a particular user. RedBeryl Privacy Policy assures that such information will be protected and not misused</p>
            <p className='description'>For purposes of this Agreement, the terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of the website. </p>




            <h4 className="mb-3 mt-3 title">2. Information we may Collect :</h4>
            <p className="description">We may collect both “Non-Personal Information” and “Personal Information” about you. “Non-Personal Information” includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. “Personal Information” includes information that can be used to personally identify you, such as your name, address and email address.</p>




            <p>
                <li className='description'>Name</li>
                <li className='description'>Job title</li>
                <li className='description'>Company Name</li>
                <li className='description'>  Address</li>
                <li className='description'>  Email Id</li>
                <li className='description'>  Mobile Number</li>
                <li className='description'> Demographic Information</li>
            </p>


            <h4 className="mb-3 mt-3 title">3.We require above information to ensure better communication and custom experience to our users like:</h4>
            <p>
                <li className="description">Internal record keeping.</li>
                <li className='description'>Identifying the user</li>
                <li className='description'>Customise the services</li>
                <li className='description'>Communicate with user for services, payments, queries, problem solving</li>
                <li className='description'>Periodic Product &amp; Promotional Communication.</li>
                <li className='description'>Market Research</li>
            </p>



            <h4 className="mb-3 mt-3 title">4. Security of Personal Data :</h4>
            <p className="description">RedBeryl assures all such information will be kept secure and protected from unauthorised access, misuse and from further sale to other parties. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by the law of land to do so.
                At any point of time user or buyer have full write to ask us what sort of information we have gathered about them by writing a mail to <strong>info@redberyltech.com</strong> We shall take responsibility to respond on any such query within 7 working days. Users may request to change, correct any information gathered about them at any point of time by writing on mail <strong>hr@redberyltech.com</strong> </p>


            <h4 className="mb-3 mt-3 title">5. Is a Privacy Policy Required by Law?</h4>
            <p className="description">This website uses Google Analytics to track information regarding your use of the website.  We may track information provided to us by your browser or use of the website, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the website, the time and date of access, and other information that does not personally identify you.  We use this information for, among other things, the operation of the website, to maintain the quality of the website, to provide general statistics regarding use of the website and for other business purposes. </p>




        </div>
    )
}
