import React from 'react'
import Footer from './Footer'
import HomeSlider from './HomeSlider'
import OurProduct from './OurProduct'
import OurServices from './OurServices'
import TechnologyWeUse from './TechnologyWeUse'
import Testimonials from './OurTestimonials'
import { useEffect } from 'react'


function HomePage() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div className='container-flude'>
      <HomeSlider />
      <OurProduct />
      <OurServices />
      <TechnologyWeUse/>
      <Testimonials/>
      <Footer />
    </div>

  )
}


export default HomePage