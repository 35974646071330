import axios from "axios";


    const BlogService=()=> {
        try {
            return axios.get(`https://www.api.redberyltech.com/api/blogs`)   
        } catch (error) {
            return[];
        }
    }

export default { BlogService,}




