import axios from "axios";



const inquiryService = (user) => {
    try {
        return axios.post("https://www.api.redberyltech.com/api/enquiry", user)
    } catch (error) {
        return [];
    }
}


export default { inquiryService, }