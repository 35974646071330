import React, { useState, useEffect } from 'react'

import Service from '../services/OurServices'
import { createSearchParams, useNavigate } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

function OurService() {

    const history = useNavigate();

    const [content, setContent] = useState("")

    const onNavigate = (event) => {

        const foundElement = data.find(obj => {
            console.log(obj.id)
            return obj.id === event;
        })


        history({
            pathname: "/service",
        })

    }

    const [data, setData] = useState([]);

    useEffect(() => {
        Service.getAllServices()
            .then(res => {
                setData(res.data)
                console.log(res.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            })
            .catch(err => (err))
    }, [])


    return (

        <section id="services" className="services sections-bg">
            <div className="container p-3" data-aos="fade-up">

                {/* <div className="section-header ">
                    <h2>Our Services</h2>
                    
                </div> */}

                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Our Services</span>
                </div><br />

                <div className="row gy-4  justify-content-center services-block" data-aos="fade-up" data-aos-delay="100"  >

                    {data.map((service, i) => {

                        return (

                            <div key={service.id} className="col-lg-4 col-md-6 d-flex align-items-stretch service-card mb-lg-0">
                                {/* <div className="row m-2"> */}
                                {/* <div className=" icon-box"> */}

                                <div class="flip-box">
                                    <div class="flip-box-inner" >
                                        <div class="flip-box-front">
                                            <img src={"./images/" + service.iconUrl} alt='...' className=' img-fluid service-bg' />
                                            <div className='text-center mt-8 services-div'>

                                                <h3 className='title justify-text text-center' style={{ color: 'white', opacity: '0.9' }}>{service.name}</h3>
                                            </div>

                                        </div>
                                        <div class="flip-box-back">

                                            <p className='description' style={{ color: 'white', fontSize: '14px' }}>{service.shortDescription.length >= 40 ? service.shortDescription.substring(0, 250) + "..." : service.shortDescription}</p>
                                            <div className='text-center '>
                                                <button type="button" className="btn btn-primary prod-btn " ><NavHashLink className="navhash" smooth
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={`/service#service${service.id}`}>Read More</NavHashLink></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        )
                    })}

                </div>
            </div>
        </section>
    );
}

export default OurService;