import axios from "axios";


const commentService = (addComment) => {
    return axios.post("https://www.api.redberyltech.com/api/comment", addComment)
}

const getCommentService = (id) => {
    return axios.get(`https://www.api.redberyltech.com/api/getByBlogs/${id}`)
}

export default {
    commentService,
    getCommentService
}