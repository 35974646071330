import React, { useState, useEffect } from 'react'
import opportinity from '../services/CurrentOpeningService'
import FileUploadService from '../services/FileUploadService';
import CandidateService from '../services/CandidateService';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Navigation } from 'swiper';
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { Modal } from 'react-bootstrap';
import { useCallback } from 'react';
import axios from 'axios';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { useLocation } from 'react-router';
import Application from '../services/CandidateUploadService';



SwiperCore.use([Navigation, Pagination, Autoplay]);

function CurrentOpening() {

    const sitekey = "6LcBVdMoAAAAAPIzPtUSa16xXXeWAleCHKYnEtQP";

    const secretkey = '6LcBVdMoAAAAABDmdR6-A8zda2o1lkqS9nX3LwLN'

    const key = '6LcBVdMoAAAAAPIzPtUSa16xXXeWAleCHKYnEtQP'

    const recaptcha = useRef(null)

    const fileInputRef = useRef(null);

    const [content, setContent] = useState("");

    const [status, setStatus] = useState(false)

    const [showMessage, setShowMesaage] = useState(false)

    const [CloseApplication, setCloseApplication] = useState(false)

    const [isCaptcha, setIsCaptcha] = useState(false)

    const [captchaKey, setCaptchaKey] = useState('')

    const captcharef = useRef(null)

    const [fullName, setFullName] = useState(null)

    const [fullNameErr, setFullNameErr] = useState('')

    const [contact, setContact] = useState(null)

    const [contactErr, setContactErr] = useState('')

    const [alternateContact, setAlternateContact] = useState(null)

    const [alternateContactErr, setAlternateContactErr] = useState('')

    const [email, setEmail] = useState(null)

    const [emailErr, setEmailErr] = useState('')



    const swiperParams = {
        // slidesPerView: 3,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 1
            },
            640: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 3,
            },
        },
    };

    const [temp, setTemp] = useState([])

    let Foundelement = {
        positionName: "",
        skill: "",
        experience: "",
        salaryRange: "",
        jobDescription: "",
        roleAndResponsibility: "",
        qualification: "",
        location: "",
        noOfOpenings: "",
        jobType: [
            {
                type: ""
            }
        ],
        language: [
            {
                language: ""
            }
        ]
    }


    const [languageArray, setlanguageArray] = useState([])


    const onNavigate = (id) => {

        console.log(id);

        Foundelement = data.find(obj => {

            if (obj.id === id) {

                setTemp(Object.assign(Foundelement, obj))

                setlanguageArray(obj.language)
            }
        })
    }

    // const lag = languageArray.map((ele) => {
    //     console.log(ele.language)
    //     return ele.language;
    // })

    // console.log(lag)



    const [data, setData] = useState([]);

    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        opportinity.CurrentOpeningservice()
            .then(res => {
                setData(res.data)
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content);

                })
            .catch(err => (err))
    }, [])



    const [application, setApplication] = useState(
        {
            CandidateName: "",
            alternative_contact_number: "",
            email: "",
            resumeFilePath: "",
            contact: "",
            positionName: "",

        })

    // handling name
    const handleName = (e) => {
        setFullNameErr('')
        let vendor = e.target.value
        if (vendor === '') {
            setFullNameErr('Please Enter Your Full Name')

        }
        if (vendor && vendor.trim() !== '') {
            if (!/^[A-Za-z\s]*$/.test(vendor)) {
                setFullNameErr("Please Enter Your Valid Full Name");
            }

        }
        setFullName(e.target.value)
    }

    // handling contact
    const handleContact = (e) => {
        setContactErr('')
        let contact = e.target.value
        if (contact === '') {
            setContactErr('Please Enter Your Contact No ')

        }
        if (contact && contact.trim() !== '') {
            if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(contact)) {
                setContactErr("Please Enter Your Valid Contact No");
            }

        }
        setContact(e.target.value)
    }

    // handling alternate contact
    const handleAlternateContact = (e) => {
        setAlternateContactErr('')
        let alternatecontact = e.target.value
        // if (alternatecontact=== '') {
        //     setAltContactErr('Please Enter Your Alternate Contactno ')

        // }
        if (alternatecontact && alternatecontact.trim() !== '') {
            if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(alternatecontact)) {
                setAlternateContactErr("Please Enter Your Valid Alternate Contactno");
            }

        }
        setAlternateContact(e.target.value)
    }

    // handling email
    const handleEmail = (e) => {
        setEmailErr('')
        let email = e.target.value
        if (email === '') {
            setEmailErr('Please Enter Your Email')

        }
        if (email && email.trim() !== '') {
            if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
                setEmailErr("Please Enter Valid Email");
            }

        }
        setEmail(e.target.value)
    }


    const onDataChange = event => {
        console.log(event.target.value);
        const value = event.target.value;
        setApplication({ ...application, [event.target.name]: value });
    }



    useEffect(() => {
        window.scroll(0, 0)

    }, []);


    function onChange(value) {
        // console.log("Captcha value:", value);
        setIsCaptcha(true)
        setCaptchaKey(value)
    }

    // useEffect(() => {
    //     axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${secretkey}&response=${captchaKey}`,
    //         {
    //             headers: {
    //                 'Access-Control-Allow-Origin': '*',

    //             },
    //         }
    //     )
    //         .then((res) => {
    //             console.log(res)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [captchaKey])




    const dt = {
        key: captchaKey
    }


    const [selectedFiles, setSelectedFiles] = useState(null);

    const selectFile = (event) => {
        console.log("file is selected")
        setSelectedFiles(event.target.files[0]);
        console.log(event.target.files[0])
    };

    const handleClose = () => {
        setFullName('')
        setAlternateContact('')
        setContact('')
        setEmail('')
        setSelectedFiles('')
    }


    const OnSubmit = (e) => {

        e.preventDefault()


        const app = {
            fullName: fullName,
            alternativeContactNo: alternateContact,
            email: email,
            contactNo: contact,
            positionName: temp.positionName,
            resumeUrl: ''
        }

        console.log(app)
        console.log('lll')




        // axios.post('/captcha', dt)
        //     .then((res) => {
        //         console.log(res)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })


        let formData = new FormData();
        console.log(selectedFiles)
        formData.append("file", selectedFiles);
        console.log(formData.get('Resume'))

        Application.applicationUploadService(app)
            .then((res) => {
                console.log(res.data.id)
                Application.candidateUploadService(formData, res.data.id)
                    .then((res) => {
                        console.log(res)
                        // window.location.reload(false)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
            })

        captcharef.current.reset()


        if (captchaKey !== '') {
            // onFileUpload();


        }





    }



    // const onFileUpload = () => {

    //     const app = {
    //         fullName: fullName,
    //         alternativeContactNo: alternateContact,
    //         email: email,
    //         contactNo: contact,
    //         positionName: temp.positionName,

    //     }


    //     let formData = new FormData();
    //     console.log(selectedFiles)
    //     formData.append("Resume", selectedFiles);
    //     console.log(formData.get('Resume'))
    //     console.log(app)
    //     formData.append("Applicant", JSON.stringify(app))

    //     console.log(formData)



    //     FileUploadService.upload(formData).then((response) => {
    //         console.log(response)
    //         if (response.status === 200) {

    //             console.log("Application send Successfully");
    //             setShowMesaage(true)


    //             setCloseApplication(true)

    //             setApplication({
    //                 CandidateName: "",
    //                 alternative_contact_number: "",
    //                 email: "",
    //                 contact: "",
    //                 positionName: "",
    //             })

    //             fileInputRef.current.value = '';

    //             setTemp(Foundelement)
    //         }
    //     },
    //         (error) => {
    //             const _content =
    //                 (error.response &&
    //                     error.response.data &&
    //                     error.response.data.message) ||
    //                 error.message ||
    //                 error.toString();

    //             setContent(_content);
    //             console.error(content);

    //         }).catch((error) => {
    //             console.log(error)
    //         })
    // }


    const [products1, setProducts1] = useState([
        {
            id: '1000',
            code: 'f230fh0g3',
            name: 'Bamboo Watch',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1000',
            code: 'f230fh0g3',
            name: 'Bamboo Watch',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            rating: 5
        }
    ]);

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };









    // slider navigation button

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    const productTemplate = (product) => {
        console.log(product)
        return (
            <div className="product-item current-opening-card">
                <div className="product-item-content">

                    <div className="mb-3">
                        <h4 className="mb-1 title text-center">{product.positionName}</h4>
                    </div>
                    <div className='grid'>
                        <div className='col-5 ml-3 grid-container'>
                            <p style={{ fontSize: '12px', fontWeight: '700' }}>Technologies</p>
                            <p style={{ fontSize: '12px', fontWeight: '700' }}>Experience</p>
                            <p style={{ fontSize: '12px', fontWeight: '700' }}>Salary Range</p>
                        </div>
                        <div className='col-7 grid-container1'>
                            <p className='salary-text justify-content-center skill-class' style={{ fontSize: '12px' }}>{product.skill.length >= 50 ? product.skill.substring(0, 45) + "..." : product.skill}</p>

                            <p className='salary-text' style={{ fontSize: '12px' }}>{product.experience.length >= 20 ? product.experience.substring(0, 15) + "..." : product.experience}</p>

                            <p className='salary-text' style={{ fontSize: '12px' }}>{product.salaryRange.length >= 20 ? product.salaryRange.substring(0, 15) + "..." : product.salaryRange}</p>
                        </div>
                     

                        <div className='d-flex mt-5 ml-3 justify-content-center'>
                            <button type="button" className="btn btn-primary m-2 text-center current-opening-btn mr-3" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => onNavigate(product.id)} >Know More</button>
                            <button type="button" className="btn btn-outline-primary text-center current-opening-btn  bg-primary-reverse mr-2 m-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => onNavigate(product.id)}>Apply Now</button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }


    // const productTemplate=(val)=>{
    //     return(
    //         <>
    //         <h5>{val.skill}</h5>
    //         </>
    //     )
    // }

   



    return (
        <section id="currentopening" className="currentopening" >
            <div className="container-fluid mt-5 p-3" data-aos="fade-up">
                {/* <div className="section-header text-white mt-3">
                    <h2>Current Openings</h2>
                </div> */}
                <div className='mt-3'>
                    <div className='section-title text-center'>
                        <span className='our-product-header text-center'>Current Openings</span>
                    </div>
                    <div className="row">
                        <div className="swiper-container ">
                            <div className="swiper-wrapper ">
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={0}
                                    modules={{ Navigation, Pagination }}
                                    {...swiperParams}
                                    ref={sliderRef}>
                                    {data.map(values => (
                                        <SwiperSlide>
                                            <>
                                                <div className='swiper-slide'>
                                                    <div className="col-md-3 col-sm-1 col-lg-12 d-flex align-items-center mb-lg-0 justify-content-center " data-aos-delay="100">
                                                        <div key={values.id} className="icon-box ">
                                                            <h4 className="title ">{values.positionName}</h4>
                                                            <div className='row mb-3'>
                                                                <div className='col-md-5 col-sm-5 col-sm-12 align-items-start grid-container'>
                                                                    <p className="description fw-bold">Technologies</p>
                                                                    <p className="description fw-bold">Experience</p>
                                                                    <p className="description fw-bold">Salary Range </p>
                                                                </div>
                                                                <div className='col-md-6 col-sm-6 justify-content-start grid-container'>
                                                                    <a className='salary-text justify-content-center skill-class'>{values.skill.length >= 50 ? values.skill.substring(0, 45) + "..." : values.skill}</a>

                                                                    <a className='salary-text'>{values.experience.length >= 20 ? values.experience.substring(0, 15) + "..." : values.experience}</a>

                                                                    <a className='salary-text'>{values.salaryRange.length >= 20 ? values.salaryRange.substring(0, 15) + "..." : values.salaryRange}</a>
                                                                </div>
                                                            </div>




                                                            <div className='d-flex  justify-content-center'>
                                                                <button type="button" className="btn btn-primary m-2 text-center text-sm mr-3" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => onNavigate(values.id)} >Know More</button>
                                                                <button type="button" className="btn btn-outline-primary text-center text-sm bg-primary-reverse mr-2 m-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => onNavigate(values.id)}>Apply Now</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div class="swiper-button-prev" onClick={handlePrev}></div>
                                <div class="swiper-button-next" onClick={handleNext}></div>
                            </div>
                        </div>
                        {/*                         
                        <div className="carousel-demo">
                         

                            <div className="">
                                <Carousel value={data} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                                    autoplayInterval={3000} itemTemplate={productTemplate} />
                            </div>

                        </div> */}



                    </div>
                </div>

                <div className="card flex currentopening-card justify-content-center">
                    <Carousel value={data} numVisible={1} numScroll={1} orientation="horizental"  verticalViewPortHeight="360px" autoplayInterval={3000}
                        itemTemplate={productTemplate} />
                </div>

                





                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header ">
                                <span className="modal-title text-header " id="exampleModalLabel">Join With Us</span>
                                <span> <button type="button" className="bi bi-x close-position" data-bs-dismiss="modal" aria-label="Close" ></button></span>



                            </div>

                            <div className="modal-body">
                                <form onSubmit={OnSubmit}>
                                    <div className='col-lg-12'>

                                        <div className=" mb-1">
                                            {/* <span className="input-group-text " id="addon-wrapping" >Full Name</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Full Name <span className='form-field-mandatory'>*</span></label></div>
                                            <input type="text" aria-label="First name"
                                                className="form-control col-md-6"
                                                // placeholder="Full Name *"
                                                name='CandidateName'
                                                onChange={handleName}
                                                value={fullName}
                                                required />
                                            <div className="invalid-feedback">
                                                {fullNameErr}
                                            </div>

                                        </div>
                                        {fullNameErr ? <p className='error-msg font-fam-for-all'>{fullNameErr}</p> : ''}

                                        <div className="  mb-1">
                                            {/* <span className="input-group-text " id="addon-wrapping" >Contact Number</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Contact <span className='form-field-mandatory'>*</span></label></div>
                                            <input type="tel" pattern="[1-9]{1}[0-9]{9}"
                                                className="form-control col-md-6"
                                                // placeholder="1234567890 *"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"
                                                name="contact" onChange={handleContact}
                                                value={contact}
                                                required />
                                            <div className="invalid-feedback">
                                                {contactErr}
                                            </div>
                                        </div>

                                        {contactErr ? <p className='error-msg font-fam-for-all'>{contactErr}</p> : ''}

                                        <div className="  mb-1">
                                            {/* <span className="input-group-text" id="addon-wrapping">Alternative No</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Alternative Contact</label></div>
                                            <input type="tel" pattern="[1-9]{1}[0-9]{9}"
                                                aria-label="Last name *"
                                                className="form-control col-md-6"
                                                // placeholder="Alternative No *"
                                                name="alternative_contact_number"
                                                onChange={handleAlternateContact}
                                                value={alternateContact}
                                                required />
                                            <div className="invalid-feedback">
                                                {alternateContactErr}
                                            </div>
                                        </div>
                                        {alternateContactErr ? <p className='error-msg font-fam-for-all'>{alternateContactErr}</p> : ''}


                                        <div className=" mb-1">
                                            {/* <span className="input-group-text" id="addon-wrapping">Mail Address</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Email <span className='form-field-mandatory'>*</span></label></div>
                                            <input type="email" className="form-control col-md-6"
                                                // placeholder="example@gmail.com *"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"
                                                name='email'
                                                onChange={handleEmail}
                                                value={email}
                                                required />
                                            <div className="invalid-feedback">
                                                {emailErr}
                                            </div>
                                        </div>
                                        {emailErr ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ''}


                                        <div className="  mb-1">
                                            {/* <span className="input-group-text" id="addon-wrapping" >Position</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Position <span className='form-field-mandatory'>*</span></label></div>
                                            <input type="text" className="form-control col-md-6"
                                                // placeholder="Enter interested position name"
                                                aria-label="Username *"
                                                aria-describedby="addon-wrapping"
                                                name='positionName'
                                                required
                                                value={temp.positionName} />
                                            <div className="invalid-feedback">
                                                please enter interested position
                                            </div>
                                        </div>



                                        <div className="mb-1">
                                            {/* <span className="input-group-text" id="addon-wrapping" >Resume</span> */}
                                            <div><label htmlFor='' className='form-label font-fam-for-all'>Resume <span className='form-field-mandatory'>*</span></label></div>
                                            <input accept=".pdf,.png, .doc ,.docx" className="form-control custom-file-label col-md-6"
                                                type="file"
                                                id="formFileMultiple"
                                                onChange={selectFile}
                                                ref={fileInputRef}
                                                required />
                                            <div className="invalid-feedback">
                                                please select right file
                                            </div>
                                        </div>
                                        <div className='currentopening-captcha'>

                                            <ReCAPTCHA
                                                sitekey={key}
                                                onChange={onChange}
                                                ref={captcharef}
                                                onExpired={e => setCaptchaKey('')}
                                            />
                                        </div>

                                        <div className=' d-flex justify-content-center p-3'>
                                            <button type="submit" disabled={fullName && alternateContact && contact && email && selectedFiles && isCaptcha ? false : true}
                                                className="btn btn-primary text-center text-lg m-2 " data-bs-target="#staticBackdrop1" data-bs-toggle="modal" data-bs-dismiss="modal"  >Submit</button>
                                            <button type="button" className="btn btn-outline-primary m-2" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close" data-bs-target="#exampleModal" >Cancel</button>
                                        </div>




                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>






                <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <span className="modal-title text-header1 " id="exampleModalLabel">{temp.positionName}</span>
                                <span><button type="button" className="bi bi-x close-position1" data-bs-dismiss="modal" aria-label="Close"></button></span>
                            </div>
                            <div className="modal-body ">
                                <p className='description'>RedBeryl Tech Solution is a product based Indian company also delivers service based project and is into staffing domain as well.</p>
                                <p className='description'>We empower consumers by providing them with one-click seamless solutions for all their requirements.</p>
                                {/* <p className='description'>{temp.jobDescription}</p> */}
                                <ul >
                                    <li className='description'>Required Skills:</li>
                                    <p className='description'>{temp.skill}</p>
                                    <li className='description'>Job Description</li>
                                    <p className='description'>{temp.jobdescription}</p>
                                    <li className='description'>Salary Range:</li>
                                    <p className='description'>{temp.salaryRange}</p>
                                    <li className='description'>Location:</li>
                                    <p className='description'>{temp.location}</p>
                                    <li className='description'>Education:</li>
                                    <p className='description'>{temp.qualification}</p>
                                    <li className='description'>Experience:</li>
                                    <p className='description'>{temp.noOfOpenings}</p>
                                    <li className='description'>Languages:</li>
                                    <p className='description'>{temp.language}</p>
                                </ul>
                            </div>

                            <div className='d-flex dialog-card justify-content-center p-3'>
                                <button type="button" className="btn btn-primary  align-items-stretch  w-25 m-2" data-bs-dismiss="modal" data-bs-target="#exampleModal" data-bs-toggle="modal"  >Apply Now</button>
                                <button type="button" className="btn  btn-outline-primary w-25 m-2" data-bs-dismiss="modal" data-bs-target="exampleModal1" >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade   dialog-box" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" show={showMessage}>
                    <div className="modal-dialog ">
                        <div className="modal-content ">
                            <div className="modal-body text-center">
                                <img src='../images/Success-Toast-image.png' width='100' height='100' />
                                <div className="text-center">
                                    <a className="description">Application Submitted Successfully</a>
                                </div>

                            </div>
                            <div className="modal-footer justify-content-center">
                                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setShowMesaage(false) }} data-bs-target="#staticBackdrop" aria-label='Close'>Close</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default CurrentOpening;