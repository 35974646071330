import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Service from '../services/OurServices'

function SingleServiceDetails() {

  const [userdataParam] = useSearchParams();

  const [Services, setServices] = useState([])

  const [subServices, setSubService] = useState()

  const [data, setData] = useState({})

  const [id,setId] = useState(userdataParam.get("id"))

  console.log(id)

  useEffect(() => {

    console.log("UseEffect  Working service ")
    Service.getAllServices()
      .then(res => {


        console.log("response data")
        console.log(res.data)
        setData(res.data)



        const foundElement = res.data.find(obj => {
          return obj.id.toString() == id
        })

        console.log("Found Element")
        console.log(foundElement)
        setServices(foundElement)

        console.log(Services)

        Services.subService.map((values) => {

          console.log("subServices title")
          console.log(values.title)
        })




      }).catch(err => (err))
  }, [id])

  return (

    <section id="single-service" className="single-service section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-header text-center">
          <h2>Service Details</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>

        <div className="row feture-tabs" data-aos="fade-up">
          <div className="col-lg-6">
            <img src="/images/3.png" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6">
            <h3>Cloude Computing</h3>
            <p></p>
            {/*<p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
          <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p> */}
          </div>

        </div>

        <div className="col-lg-6">
          <img src="assets/img/features.png" className="img-fluid" alt="" />
        </div>

        <div className="section-header text-center">
          <h2>Sub Services</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>


        <div className="row mt-4 p-3">

          {/* {Service.subService.map((obj) => ( */}

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i></div>
                <h4>
                  <a href="">HRMS</a>
                </h4>
                <p>No data found</p>
              </div>
            </div>
          {/* ))} */}
        </div>
      </div>
    </section>
  )
}


export default SingleServiceDetails;
