import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import TechnologyWeUse from '../services/TechnologyWeUse';

SwiperCore.use([Autoplay, Pagination]);

const Slider = () => {

  const [data, setData] = useState([])

  const [content, setContent] = useState("")

  const swiperParams = {
    slidesPerView: 6,
    autoplay: {
      delay: 5,
      disableOnInteraction: false,

    },
    watchSlidesProgress: true,
    grabCursor: true,
    speed:3000,
    loop: true,
    // pagination: {
    //   clickable: true,
    // },


    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      992: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    },
  };

  useEffect(() => {
    TechnologyWeUse.TechnologyWeUse()
      .then((response) => {
        console.log(response.data)

        setData(response.data)

      }, (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);
        console.error(content);

      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  // console.log(data.name)




  return (

    <section id='technology' className='technology'>

      <div className='container mt-4'>
        {/* <div className="text-center" data-aos="fade-up">
          <div className="technology-header">
            <h2>Technologies We Use</h2>
          </div>
        </div> */}
        <div className='section-title text-center'>
          <span className='our-product-header text-center'>Technologies We Use</span>
        </div><br />
        
        <Swiper {...swiperParams}>
          {data.map((obj) => (
            <SwiperSlide>
              <div className='item-align-center swiper-slide img-fluid col-md-5 col-lg-7 col-sm-2'>
                <img src={"./images/technologies/" + obj.logoUrl} alt="Slide 1" width={150} className='img-flude' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
       
      </div>
    </section>
  );
};

export default Slider;
