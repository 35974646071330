import React, { useState, useEffect } from 'react'
import AboutUsService from "../services/AboutUsService";


function OurMission() {

    const [data, setData] = useState([]);

    const [content, setContent] = useState("")

    useEffect(() => {
        AboutUsService.aboutusService()
            .then((response) => {
                console.log(response)
                setData(response.data)
            },(error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            }).catch((error) => {
                console.log(error)
            })
    }, []);

    return (
        <div id="mission" className="mission">
            <div className=" mt-4">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                    {data.map(values => (
                        <div  className="row">
                            <div className="col-md-6 d-flex justify-content-center ">
                                <img src={values.missionUrl} className="circle-border" alt="..." height="200px" width='210px' />
                            </div>
                            <div className="col-md-6 mt-4 ">
                                <h3 className="title">Our Mission</h3>
                                <p className="description">{values.mission}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurMission;