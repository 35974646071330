import axios from "axios";



const TechnologyWeUse = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/technologies/get")
    } catch (error) {
        return [];
    }
}

export default { TechnologyWeUse, }