import React from 'react'
import Footer from './Footer';
import ProductInfo from './ProductInfo';
import ConnectUs from './ConnectUs';


function ProductPage() {

  return (
    <>
      <div className='product-page'>
        <ProductInfo />
        {/* <ConnectUs /> */}
      </div>
      <Footer />
    </>
  )
}

export default ProductPage