import axios from "axios";



const testimonials = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/testimonial/get")
    } catch (error) {
        return [];
    }
}

export default { testimonials }