import React, { useState, useEffect } from "react";

import Service from '../services/OurServices'
import { useContext } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";



const ServiceInfo = () => {

    const history = useNavigate();

    const [data, setData] = useState([]);

    const [showButton, setShowButton] = useState(true);

    const [content, setContent] = useState("")


    useEffect(() => {
        Service.getAllServices()
            .then(res => {
                setData(res.data)
                console.log(res.data)

            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            }).catch(err => (err))
    }, [])

    console.log(data)

    const [serviceId1, setServiceId1] = useState()

    const [serviceId, setServiceId] = useState()


    const onNavigate = (event) => {
        setServiceId(event)
    }



    const onNavigate1 = (event) => {
        setServiceId1(event)
    }

    const cardimage = {
        backgroundImage: 'url("../public/images/clients/client-1.png")'
    }





    return (
        <section id="service-list" className="service-list section-bg">
            <div className="container ">
{/* 
                <div className="section-header">
                    <h2>Our Services</h2>
                
                </div> */}

                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Our Services</span>
                </div>

                {data.map((service, index) => {

                    if (index % 2 === 0) {

                        return (
                            <>
                                <div id={`service${service.id}`} key={service.id} className="row content justify-content-center product-table-card mt-4 align-items-center">
                                    <div className="col-md-6 align-items-center" data-aos="fade-right">
                                        <img src={"./images/" + service.imageUrl} className="img-fluid mt-3" alt=" Service Image" width="340px" height="440px" />
                                    </div>
                                    <div className="col-md-6" data-aos="fade-left">
                                        <a className="title">{service.name}</a>
                                        <p className="description">{service.shortDescription}</p>
                                        {service.SubServices.length == 0 ? ("") : (
                                            <button type="button" className="btn btn-primary m-2" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1" onClick={() => onNavigate(service.id)} >Read More</button>
                                        )}
                                    </div>
                                    <div className="collapse " id="collapseExample1">
                                        <div className="row mt-3 justify-content-center">
                                            {service.SubServices.map((obj) => {
                                                if (service.id == serviceId) {
                                                    return <><div key={obj.id} className="col-md-12 col-lg-4 wow bounceInUp mt-3 mb-4 " data-aos="zoom-in" data-aos-delay="100" >
                                                        <div className="box">
                                                            {/* <div className="icon"><i className="bi bi-briefcase"></i></div> */}
                                                            <p className="title">{obj.title}</p>
                                                            <p className="description">{obj.subTitle}</p>
                                                        </div>
                                                    </div>
                                                    </>
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    return (
                        <>
                        <div id={`service${service.id}`} className="product-table-card1 mt-4 p-3">
                            <div key={service.id} className="row content  justify-content-center align-items-center mt-5 align-items-center">
                                <div className="col-md-6 order-1 order-md-2 d-flex justify-content-end" data-aos="fade-left">
                                    <img src={"./images/" + service.imageUrl} className="img-fluid mt-3 justify-content-end" alt="Service Image" width="340px" height="440px" />
                                </div>
                                <div className="col-md-6 order-2 order-md-1" data-aos="fade-right">
                                    <a className="title">{service.name}</a>
                                    <p className="description">{service.shortDescription}</p>
                                    {service.SubServices.length == 0 ? ("") : (
                                        <button type="button" className="btn btn-primary m-2" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={() => onNavigate1(service.id)}>Read More</button>
                                    )}
                                </div>
                            </div>
                            <div className="collapse" id="collapseExample">
                                <div className="row mt-3 justify-content-center">

                                    {service.SubServices.map((obj) => {
                                        if (service.id == serviceId1) {
                                            return <><div key={obj.id} className="col-md-12 col-lg-4 wow bounceInUp mt-3 mb-4" data-aos="zoom-in" data-aos-delay="100">
                                                <div className="box" >
                                                    {/* <div className="icon"><i className="bi bi-briefcase"></i></div> */}
                                                    <a className="title">{obj.title}</a>
                                                    <p className="feature-description">{obj.subTitle}</p>
                                                </div>
                                            </div>
                                            </>
                                        }
                                    })
                                    }
                                </div>
                            </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </section>
    );
}

export default ServiceInfo;

//onClick={() => onNavigate(service.id)}


// onClick={() => onNavigate(service.id)}