import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination]);

const eventSection = () =>{

    const swiperParams = {
        // slidesPerView: 6,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 0
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 0
            }
        },
    };

return(
    <section id='event' className='event'>

        <div className='container mt-3 mb-3'>
            <div className="event-header text-center" data-aos="fade-up">
                <div className="title">
                    <h2>Events We celebrat</h2>
                </div>
            </div>

            <Swiper {...swiperParams}>
                {/* {data.map((obj) => ( */}
                    <SwiperSlide>
                    <div className='col-lg-3 d-flex align-items-stretch  mx-auto'>
                            <img src="./images/events/images.jpg" alt="Slide 1"  className='img-flude' />
                        </div>
                    </SwiperSlide>
                {/* ))} */}
            </Swiper>
        </div>
    </section>

    );
};


export default eventSection;