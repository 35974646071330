import React from 'react'
import BlogsInfo from './BlogsInfo';
import Footer from './Footer';

function BlogPage() {
  return (
    <div className='blog-page'>
      <BlogsInfo />
      <Footer />
    </div>
  )
}

export default BlogPage;