import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { BsPlusCircleFill } from 'react-icons/bs'
import { BiSolidComment } from 'react-icons/bi';
import commentService from "../services/commentService";
import { Modal } from 'react-bootstrap';

function BlogSection() {

    const [showMessage, setShowMesaage] = useState(false)

    const [userdataParam] = useSearchParams();

    const [comment, setComment] = useState([])

    const [refresh, setrefresh] = useState([])

    const [content, setContent] = useState("");



    let Blogs = {
        id: userdataParam.get("BlogId"),
        imagePath: userdataParam.get("BlogImagePath"),
        publishDate: userdataParam.get("BlogPublishDate"),
        description: userdataParam.get("BlogDescription"),
        authorName: userdataParam.get("BlogAuthor"),
        status: userdataParam.get("BlogStatus"),
        title: userdataParam.get("BlogTitle"),
        category: userdataParam.get("Blogcategory")
    }


    useEffect(() => {
        commentService.getCommentService(Blogs.id)
            .then((response) => {
                setComment(response.data)
                console.log(response.data)
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content)
                })
            .catch((error) => {
                console.log(error)
            })
    }, [refresh])

    const [addComment, setAddComment] = useState({
        name: "",
        comment: "",
        commentedOn: "",
        blogs: {
            id: userdataParam.get("BlogId"),
            imageUrl: userdataParam.get("BlogImagePath"),
            postedOn: userdataParam.get("BlogPublishDate"),
            description: userdataParam.get("BlogDescription"),
            authorName: userdataParam.get("BlogAuthor"),
            status: userdataParam.get("BlogStatus"),
            title: userdataParam.get("BlogTitle"),
            category: userdataParam.get("Blogcategory"),
            authorImageUrl: userdataParam.get("BlogAuthorImage"),
            preference: userdataParam.get("BlogsPreference")
        }
    });

    const onDataChange = (event) => {
        setAddComment({
            ...addComment, [event.target.name]: event.target.value
        })
    };


    const OnSubmit = (event) => {
        event.preventDefault();

        console.log(addComment)

        commentService.commentService(addComment)
            .then((response) => {
                console.log(response.data)
                if (response.status === 200) {
                    setrefresh(response.data)
                    console.log("Message Send Successfully");
                    setShowMesaage(true)

                    // const timeoutvar = setTimeout(() => {
                    //     setShowMesaage(false);
                    // }, 3000);
                    // return () => clearTimeout(timeoutvar)


                }
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content)
                })
            .catch((error) => {
                console.log(error)
            })

        setAddComment({
            comment: "",
            name: "",
            commentedOn: ""
        })
    }


    const closeMesaage = () => {
        setShowMesaage(false)
    }

    return (
        <section className="blog-section container " id="blog-section">
            <div className='row  justify-content-center'>
                <div className='col-md-8  justify-content-center '>
                    <h1 className="title m-2"> {Blogs.title}.</h1>

                    <div className="d-flex align-items-center m-2">
                        <img src="../images/blog-author.jpg" alt="..." className="img-fluid post-author-img" width="50px" height="50px" />
                        <div className="post-meta">
                            <p className="description">{Blogs.authorName}</p>
                            <p className="description">
                                <time datetime="2022-01-01 description">
                                    {Blogs.publishDate}
                                </time>
                            </p>
                        </div>
                    </div>
                    <div className='col-md-12  justify-text'>
                        <img src="../images/blog-1.jpg" height="250px" width="700px" className="img-fluid blog-img" />
                    </div>
                    <div>
                        <p className="description">
                            {/* {Blogs.description} */}
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className="description">
                            {/* {Blogs.description} */}
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                    <hr></hr>

                    <div className="d-flex">
                        <i className="m-2 bx bx-message-detail"></i>
                        <a className="m-2 description">{comment <= 0 ? "0" : comment.length}</a>
                        <i className="m-2 bx bx-comment-add " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
                    </div>

                    <hr></hr>

                    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        {/* <div className="offcanvas-header">
                            <h5 id="offcanvasRightLabel" className="comment-title">Comments</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div> */}

                        <div className="modal-header comment-header">
                            <span className="modal-title comment-title " id="exampleModalLabel">Comments</span>
                            <span>
                                <button type="button" className="bi bi-x comment-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasRight" aria-label="Close" ></button>
                            </span>




                        </div>

                        <div className="offcanvas-body">
                            <div className="row " >
                                <div className="panel panel-default">
                                    <div className="panel-body panel-scrollable">
                                        <form onSubmit={OnSubmit} >
                                            <div className="mb-3">
                                                <label className="form-label">Name</label>
                                                <input type="text" className="form-control " id="exampleFormControlInput1" placeholder="Name" name="name" value={addComment.name} required onChange={onDataChange} />
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlTextarea1" className="form-label">Comment </label>
                                                <textarea className="form-control text-area" id="exampleFormControlTextarea1" rows="3" placeholder="Comment" name="comment" value={addComment.comment} required onChange={onDataChange} />
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button type="submit" disabled={addComment.name && addComment.comment ? false : true} className="btn btn-primary"  > Submit</button>
                                            </div>
                                        </form>
                                        {comment.map((obj) => (
                                            <div key={obj.id} className="comment-card">
                                                <hr></hr>
                                                <p className="description comment-username">{obj.name}</p>
                                                <p className="col-md-6 col-lg-12 description">{obj.comment}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" show={showMessage}>
                <div className="modal-dialog ">
                    <div className="modal-content ">
                        <div className="modal-body text-center">
                            <img src='../images/Success-Toast-image.png' width='100' height='100' />
                            <div className="text-center">
                                <a className="description">Comment Added Successfully</a>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setShowMesaage(false) }}>Close</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    );
}

export default BlogSection;