import React, { useEffect, useState } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import FootterService from '../services/FootterService';
import AboutUsService from '../services/AboutUsService';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Footer() {

  const [content, setContent] = useState("")


  const [data, setData] = useState([])

  const [Fotterdata, setFotterdata] = useState([])

  useEffect(() => {
    FootterService.FootterService()
      .then(response => {
        // console.log(response.data)
        setFotterdata(response.data)
      },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setContent(_content);
          // console.error(content);

        })
      .catch(error => (error))



    AboutUsService.aboutusService()
      .then(response => {
        // console.log(response)
        setData(response.data)
      },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setContent(_content);
          // console.error(content);

        })
      .catch((error) => (error))
  }, [])



  let facebook, instagram, twitter, linkedIn;

  data.map((obj) => {
    facebook = obj.fb
    instagram = obj.insta
    twitter = obj.twitter
    linkedIn = obj.linkedIn
  })







  return (
    <div>
      <footer id='footer'>
      <svg viewBox='0 0 1434 152'>
            <g fillRule='none-zero' fill='none'>
              {/* <path d='M 0 19.805 S 312.914 -45.927 720 62.11 c 407.09 108.038 720 13.416 720 13.416 V 677 H 0 V 19.804z' fill='#008df5'>

              </path> */}
              {/* <path d='M -6 67.433 s 314 -115.434 720 -0.434 s 720 56.156 720 56.156 v 815.344 H -6 V 67.432z' fill='#025'></path> */}
              {/* <path d='M -6 67.433 s 314 -40.434 720 -0.434 s 720 56.156 720 56.156 v 815.344 H -6 V 67.432z' fill='#025'></path> */}
              {/* <path d='M 100 350 q 150 -300 300 0' fill='#025'></path> */}

            </g>
          </svg>
        <div className="footer-top ">
         


          <div className="container">
            <div className="row">

              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4 className='mb-3'>Home</h4>
                <ul>
                  <li><i className="bx  "></i> <a href='/aboutus'>About us</a></li>
                  <li><i className="bx  "></i> <a href='/service'>Services</a></li>
                  <li><i className="bx  "></i> <a href='/product'>Products</a></li>
                  <li><i className="bx  "></i> <a href='/career'>Career</a></li>
                  <li><i className="bx  "></i> <a href='/blog'>Blogs</a></li>
                  <li><i className="bx  "></i> <a href='/contactus'>Contact Us</a></li>
                </ul>
              </div> */}
              {/* 
              <div className="col-lg-2 col-md-3 footer-links">
                <img src='../images/orglogo-removebg-preview.png' width={"200px"} height={"60px"} />
                <div className="social-links">
                  <a href={twitter} className="twitter"><i className="bi bi-twitter"></i></a>
                  <a href={facebook} className="facebook"><i className="bi bi-facebook"></i></a>
                  <a href={instagram} className="instagram"><i className="bi bi-instagram"></i></a>
                  <a href={linkedIn} className="linkedin"><i className="bi bi-linkedin"></i></a>
                </div>
              </div> */}

              <div className="col-lg-2 col-md-3 footer-links ">
{/* 
                <div className='container'>
                  <div className='row'>
                    <div className="col-lg-12 col-md-12 footer-links"> */}
                      <img src='../images/orglogo-removebg-preview.png' width={"180px"} height={"50px"} />
                      <p className='footer-text'>"Cloud Accelerator Migration,Seamless Data Migration, Expert Staffing, HRMS Excellence: Your Tech Transformation Partner"</p>
                      {/* <div className="social-links">
                        <a href={twitter} className="twitter"><i className="bi bi-twitter"></i></a>
                        <a href={facebook} className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href={instagram} className="instagram"><i className="bi bi-instagram"></i></a>
                        <a href={linkedIn} className="linkedin"><i className="bi bi-linkedin"></i></a>
                      </div> */}
                    {/* </div>
                  </div>
                </div> */}

              </div>




              <div className='col-lg-9 col-md-9 d-flex align-items-center'>
                <div className='row fotter-row'>
                  {Fotterdata.map((obj) => {
                    return (
                      <div className="col-lg-4 col-md-6 footer-links">
                        <h4 className='mb-3 footer-title '>{obj.title}</h4>
                        <ul>
                          {obj.ItemLists.map((ele) => {
                            
                            // return (<li className='footer-data'><a className='footer-data' href={ele.pagePath}>{ele.name}</a></li>)
                            return (<li className='footer-data'><NavHashLink className='footer-data' smooth
                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={ele.pagePath}>{ele.name}</NavHashLink></li>)
                          })}
                        </ul>
                      </div>)
                  })}
                </div>
              </div>

              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4 className='mb-3'>Products</h4>
                <ul>
                  <li><i className="bx  " ></i> <a href='/product'>IBS</a></li>
                  <li><i className="bx  "></i> <a href='/product'>Optimus Prime</a></li>
                  <li><i className="bx  "></i> <a href='/product'>Optimus Prime lite</a></li>
                  <li><i className="bx  "></i> <a href='/product'>HRMS</a></li>
                </ul>
              </div> */}

              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4 className='mb-3'>About Us</h4>
                <ul>
                  <li><i className="bx  "></i> <a href='/aboutus'>Mission</a></li>
                  <li><i className="bx  "></i> <a href='/aboutus'>Vision</a></li>
                  <li><i className="bx  "></i> <a href='/aboutus'>Values</a></li>
                  <li><i className="bx  "></i> <a href='/aboutus'>Technologies</a></li>
                  <li><i className="bx  "></i> <a href='/aboutus'>Clients</a></li>
                  <li><i className="bx  "></i> <a href='/aboutus'>Testimonials</a></li>
                </ul>
              </div> */}


              {/* <div className="col-lg-3 col-md-6 footer-links">
                <h4 className='mb-3'>Career</h4>
                <ul>
                  <li><i className="bx  "></i> <a href='/career'>Life at Redberyltech</a></li>
                  <li><i className="bx  "></i> <a href='/career'>Diversity and Inclusion</a></li>
                  <li><i className="bx  "></i> <a href='/career'>Returnship Program</a></li>
                  <li><i className="bx  "></i> <a href='/career'>Jobs by Location</a></li>
                </ul>
              </div> */}

              <div className='d-flex mt-4 footer-copyright align-items-center justify-content-center'>
                <a className='copyright p-1'>© RedBerylTech Solutions Pvt Ltd. 2023 All rights Reserved |</a>
                <Link className='copyright p-1' to='/privacy-policy'> Privacy Policy |</Link>
                {/* <a className='copyright p-1'> Terms of Service</a> */}
                <div className="social-links">
                        <a href={twitter} className="twitter"><i className="bi bi-twitter"></i></a>
                        <a href={facebook} className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href={instagram} className="instagram"><i className="bi bi-instagram"></i></a>
                        <a href={linkedIn} className="linkedin"><i className="bi bi-linkedin"></i></a>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Footer;

{/* <li><i className="bx  "></i> <a href='/service'>Cloud Technology</a></li>
<li><i className="bx  "></i> <a href='/service'>Software Modernization</a></li>
<li><i className="bx  "></i> <a href='/service'>Test Automation</a></li>
<li><i className="bx  "></i> <a href='/service'>Data Migration</a></li>
<li><i className="bx  "></i> <a href='/service'>Talent Staffing</a></li> */}