import HomePage from './components/HomePage'
import BlogPage from './components/BlogPage'
import CareerPage from './components/CareerPage'
import ProductPage from './components/ProductPage'
import ServicesPage from './components/ServicePage'
import Navbar from './components/Navbar';
import ContactUspage from './components/ContactUspage'
import React from 'react';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import '../src/App.css'
import {
  Routes,
  Route,
  BrowserRouter,
  HashRouter,
} from 'react-router-dom';
import BlogSection from './components/BlogSection';
import AboutUsPage from './components/AboutUsPage';
import SingleServiceDetails from './components/SingleServiceDetails'
import SingleProductDeatils from './components/SingleProductDetails'
import ErrorBoundary from './errorboundary/ErrorBoundary'

import PrivacyPolicy from './components/privacy-policy'
import Demo from './components/demo'



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleVisible);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleVisible);
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({ visible: true });
    } else if (scrolled <= 300) {
      this.setState({ visible: false });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behavior
         in place of 'smooth' */
    });
  };

  render() {


    const { visible } = this.state;

    return (
      <ErrorBoundary>
        <HashRouter basename='/'>
          <div className='container-flude'>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/product" element={<ProductPage />} />
              <Route exact path="/productinfo" element={<SingleProductDeatils />} />
              <Route exact path="/service" element={<ServicesPage />} />
              <Route exact path='/serviceinfo' element={<SingleServiceDetails />} />
              <Route exact path="/career" element={<CareerPage />} />
              <Route exact path="/blog" element={<BlogPage />} />
              <Route path="/blog/blogsection" element={<BlogSection />} />
              <Route exact path="/aboutus" element={<AboutUsPage />} />
              <Route exact path="/contactus" element={<ContactUspage />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route  exact path="/demo" element={<Demo />}/>
            </Routes>
            <div className='button'>
              <BsFillArrowUpCircleFill
                onClick={this.scrollToTop}
                style={{ display: visible ? 'inline' : 'none' }}
              />
            </div>
          </div>
        </HashRouter>
      </ErrorBoundary>
    );
  }
}

export default App;






