import BlogService from "../services/BlogService";
import React, { useState } from 'react'
import { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";



function BlogsInfo() {

    const history = useNavigate();

    const [data, setData] = useState([]);

    const [content, setContent] = useState("");


    const onNavigate = (i) => {
        console.log(i)

        const foundElement = data.find(obj => {
            console.log(obj.id)
            return obj.id === i;
        });
        console.log(foundElement)



        history({
            pathname: "/blog/blogsection",
            search: createSearchParams({
                BlogId: foundElement.id,
                BlogImagePath: foundElement.imageUrl,
                BlogPublishDate: foundElement.postedOn,
                BlogDescription: foundElement.description,
                BlogAuthor: foundElement.authorName,
                BlogStatus: foundElement.status,
                BlogTitle: foundElement.title,
                Blogcategory: foundElement.category,
                BlogAuthorImage: foundElement.authorImageUrl,
                BlogsPreference: foundElement.preference
            }).toString()
        })
    }



    const [currentPage, setCurrentPage] = useState(1);

    const recordPerPage = 6;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const record = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        BlogService.BlogService()
            .then(res => {
                setData(res.data)

                console.log(data.length)
                console.log(res.data)
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);

                    console.error(content);
                })
            .catch(err => {
                console.log(err)
            })
    }, [])


    function previousPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }

    }

    function changePage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (

        <section id="recent-posts" className="recent-posts sections-bg">
            <div className="container mt-5 pb-2" data-aos="fade-up" >

                {/* <div className="section-header">
                    <h2>Blog Posts</h2>
                </div> */}

                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Blog Posts</span>
                </div>

                <div className="row align-content-center ">

                    {record.map((blog) => (

                        <div key={blog.id} className="col-xl-4 col-md-6 mt-5">
                            <div className="card-container m-2 ">
                                <div className="card card-1">
                                    <img src='../images/abstract-images-3.jpg' className="img-flude"></img>
                                </div>
                                <div className="card card-3 d-flex align-items-center">
                                    <a className="blog-category">{blog.category}</a>
                                </div>
                                <div className="card card-2 align-items-center  mt-2">
                                    <p className="title">{blog.title.length >= 55 ? blog.title.substring(0, 50) + '...' : blog.title}</p>
                                        <button type="button" className="btn btn-primary w-50" onClick={() => onNavigate(blog.id)}>Read More</button>
                                </div>
                            </div>

                        </div>
                    ))}


                    {/* pagination component */}
                    <nav>
                        <ul class="pagination justify-content-center mt-3">
                            <li class="page-item ">
                                <a class="page-link" href="#" onClick={previousPage}>Previous</a>
                            </li>
                            {
                                numbers.map((n, i) => (
                                    <li class={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                        <a className="page-link" href="#" onClick={() => changePage(n)}>{n}</a>
                                    </li>
                                ))
                            }

                            <li class="page-item">
                                <a class="page-link" href="#" onClick={nextPage}>Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>

    );
}

export default BlogsInfo;