import React, { useState, useEffect } from 'react'
import AboutUsService from "../services/AboutUsService";


function OurVision() {

    const [data, setData] = useState([]);

    const [content, setContent] = useState("")
    
    useEffect(() => {
        AboutUsService.aboutusService()
            .then((response) => {
                console.log(response)
                setData(response.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            }).catch((error) => {
                console.log(error)
            })
    }, []);

    return (
        <div id="vision" className="our-vision">
            <div className=" mt-4">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                    {data.map(values => (
                        <div className="row ms-1">
                            <div className="col-md-6 mt-4">
                                <h3 className="title">Our Vision</h3>
                                <p className="description">{values.vision}</p>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center ">
                                <img src={values.visionUrl} className="circle-border" alt="..." height="200px" width='210px' />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurVision;


/*                         <div className="row justify-content-center">
                            <div className="col-md-3 ">
                                <img src="../images/core-values.png" className="rounded-circle" alt="..." height="200px" width='210px' />
                            </div>
                            <div className="col-md-7 mt-4">
                                <p className="title">Our Values</p>
                                <p className="values-text">{val.values}</p>
                            </div>
                        </div>*/ 