import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import React from 'react'
import { NavHashLink } from "react-router-hash-link";

import Products from '../services/ProductService';

import Service from '../services/OurServices';

import { useState } from "react";
import { useEffect } from "react";

function Navbar() {

    const [Productdata, setProductData] = useState([]);

    const [serviceData, setServiceData] = useState([])

    const [content, setContent] = useState("");

    useEffect(() => {
        Products.OurProducts()
            .then(res => {
                setProductData(res.data)
                console.log(res.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);
            })
            .catch(err => (err))


        Service.getAllServices()
            .then(res => {
                setServiceData(res.data)
                console.log(serviceData)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);

            })
            .catch(err => (err))
    }, [])



    return (
        <nav className="navbar navbar-expand-lg fixed-top shadow bg-white col-sm-12 col-md-12 col-lg-12 " >
            <div className="container">

                <img src="../images/orglogo.jpeg" alt="org " className='img-fluid' width={"180px"} height={"50px"} />

                <button className="navbar-toggler justify-content-end justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    {/* <span className="navbar-toggler-icon"></span> */}
                    <GiHamburgerMenu className="navbar-toggler-icon" ></GiHamburgerMenu>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" >
                    <ul className="navbar-nav  menu-navbar-nav text-center ">
                        <li className="nav-item ">
                            <Link className="nav-link active text-dark hover-underline-animation " aria-current="page" to="/" >Home</Link>
                        </li>

                        <li className="nav-item dropdown border-0 ">
                            <Link className="nav-link  text-dark hover-underline-animation" href="/product" role="button" aria-expanded="false" to='/product' >
                                Products</Link><i className='bi bi-chevron-down  mt-1'></i>
                            <ul className="dropdown-menu shadow  bg-body ">
                                {Productdata.map((obj) => {
                                    return (<li>
                                        <NavHashLink className='dropdown-item border-0' smooth
                                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={`/product#product${obj.id}`}>{obj.name}</NavHashLink></li>)
                                    {/* <li><a className="dropdown-item border-0" href="/product" >Optimus Prime </a></li>
                                <li><a className="dropdown-item border-0" href="/product" >Optimus Prime Lite</a></li>
                                <li><a className="dropdown-item border-0" href="/product"  >HRMS Automation</a></li> */}
                                })}
                            </ul>
                        </li>
                        <li className="nav-item dropdown border-0 " >
                            <Link className="nav-link active text-dark hover-underline-animation" aria-current="page" to="/service" >Services</Link>
                            <i className='bi bi-chevron-down mt-1'></i>
                            <ul className="dropdown-menu shadow  bg-body ">

                                {serviceData.map((obj) => {
                                    return (<li><NavHashLink className='dropdown-item border-0' smooth
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={`/service#service${obj.id}`}>{obj.name}</NavHashLink></li>)
                                    {/* <li><a className="dropdown-item border-0" href="/service" >Cloud Technology Solutions</a></li>
                                <li><a className="dropdown-item border-0" href="/service" >Software Modernization</a></li>
                                <li><a className="dropdown-item border-0" href="/service" >Test Automation</a></li>
                                <li><a className="dropdown-item border-0" href="/service" >Data (DB / DW) Migration</a></li>
                                <li><a className="dropdown-item border-0" href="/service" >Talent Staffing</a></li> */}
                                })}
                            </ul>
                        </li>
                        <li className="nav-item" >
                            <Link className="nav-link active text-dark hover-underline-animation" aria-current="page" to="/career" >Careers</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link active text-dark hover-underline-animation" aria-current="page" to="/blog">Blogs</Link>
                        </li> */}

                        <li className="nav-item">
                            <Link className="nav-link active text-dark hover-underline-animation" aria-current="page" to="/aboutus">About Us</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active text-dark hover-underline-animation" aria-current="page" to="/contactus">Contact Us</Link>
                        </li>

                        <li className="nav-item">

                        </li>





                    </ul>

                </div>
                {/* <button className="btn btn-primary btn-nav text-center">
                        <span>Get Started</span>
                        <span>-it's free</span>
                    </button> */}
            </div>
        </nav>
    )
}

export default Navbar;

