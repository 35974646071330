import React from 'react'
import ConnectUs from './ConnectUs'
import Footer from './Footer';
function ContactUspage() {
  return (
    <div className='contact-us-page'>
      <ConnectUs />
      <Footer />
    </div>
  )
}

export default ContactUspage;
