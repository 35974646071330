import axios from "axios";


const candidateUploadService = (app,id) => {
    try {
        return axios.post(`https://www.api.redberyltech.com/api/uploadResume/${id}`, app)
    } catch (error) {
        return [];
    }

}

const applicationUploadService = (app) => {
    try {
        return axios.post("https://www.api.redberyltech.com/api/applicant", app)
    } catch (error) {
        return [];
    }

}


 const Application= {
    candidateUploadService,
    applicationUploadService
}

export default Application