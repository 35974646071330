import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import PartnerService from '../services/PartnerService'

SwiperCore.use([Autoplay, Pagination]);



function OurPartner() {

  const [content, setContent] = useState("")

  // const swiperParams = {
  //   slidesPerView: 6,
  //   autoplay: {
  //     delay: 1000,
  //     disableOnInteraction: false,
  //   },
  //   pagination: {
  //     clickable: true,
  //   },
  //   breakpoints: {
  //     320: {
  //       slidesPerView: 2,
  //       spaceBetween: 0
  //     },
  //     480: {
  //       slidesPerView: 3,
  //       spaceBetween: 0
  //     },
  //     640: {
  //       slidesPerView: 4,
  //       spaceBetween: 0
  //     },
  //     992: {
  //       slidesPerView: 5,
  //       spaceBetween: 0
  //     }
  //   },
  // };

  const swiperParams={
    speed: 3000,
  autoplay: {
    delay: 5,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
  loop: true,
  slidesPerView: "auto",
  watchSlidesProgress: true,
  grabCursor: true,
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 0
    },
    480: {
      slidesPerView: 3,
      spaceBetween: 0
    },
    640: {
      slidesPerView: 4,
      spaceBetween: 0
    },
    992: {
      slidesPerView: 5,
      spaceBetween: 0
    }
  }
  }


  const [data, setData] = useState([]);

  useEffect(() => {
    PartnerService.getAllPartner()
      .then((response) => {
        console.log(response.data)
        setData(response.data)
      }, (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);
        console.error(content);

      }).catch((error) => {
        console.log(error)
      })
  }, []);

  return (
    <section id="clients" className="clients section-bg">
      <div className="container mt-5" data-aos="fade-up">

        {/* <div className="section-header text-center">
          <h2>Our Clients</h2>
        </div> */}

        <div className='section-title text-center'>
          <span className='our-product-header text-center'>Our Clients</span>
        </div>

        <Swiper {...swiperParams}>
          {data.map((obj) => (
            <SwiperSlide>
              <div className='item-align-center clients-slider  col-md-5 col-lg-7 col-sm-2 '>
                <img src={"./images/clients/" + obj.logoUrl} className="img-flude swiper-slide" alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    </section>
  )
}

export default OurPartner