
import OurMission from "./OurMission";
import OurVision from "./OurVision";
import ConnectUs from './ConnectUs'
import Fotter from './Footer'
import OurValues from "./OurValues";
import OurPartner from '../components/OurPartner'
import TechnologyWeUse from '../components/TechnologyWeUse'
import AboutUs from "./AboutUs";
import OurTestimonials from "./OurTestimonials";

import EventSection from './EventSection'

function AboutUsPage() {
    return (<>
        <div className="container about-us-page">
            <AboutUs />
            <OurMission />
            <OurVision />
            <OurValues /><br></br>
            <TechnologyWeUse />
            <OurPartner />
            <OurTestimonials />
            {/* <EventSection /> */}
        </div>
        <Fotter />
    </>
    );
}

export default AboutUsPage;