import axios from "axios";



const FootterService = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/footer/get")
    } catch (error) {
        return [];
    }
}


export default {
    FootterService,
}