import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import Products from '../services/ProductService'

function SingleProductDeatils() {

    const [userdataParam] = useSearchParams();

    const [data, setData] = useState([])

    const [description, setdescription] = useState([])

    const [temp,setTemp] = useState([])

    const [id, setId] = useState(userdataParam.get("id"))


    useEffect(() => {

        console.log("UseEffect working product")

        Products.GetSingleProduct(id)
            .then(res => {
                console.log("Api Response")
                console.log(res.data)
                setData(res.data);

                console.log(typeof (data.feature))

                data.feature.map((obj) => {

                    console.log(typeof (obj))

                    obj.featureList.map((val) => {

                        console.log(typeof (val))

                        console.log(val)

                        setdescription(prevArray => [...prevArray, val]);
                    })
                })

                setTemp(description)

                console.log(temp)

                // featureListobj.featureList.map((val) => {
                //     console.log(val.description)
                // })
            })
            .catch(err => (err))
    }, [id])



    return (
        <section id="product-details" className="product-details">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h2>Product Details</h2>
                </header>

                <div className="row">
                    <div className="col-lg-6">
                        <img src="assets/img/features.png" className="img-fluid" alt="" />
                    </div>
                </div>
                <div className="row feture-tabs" data-aos="fade-up">
                    <div className="col-lg-6">
                        <h3>
                            {data.name}
                        </h3>
                        <a>
                            {data.longDescription}
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <img src="/images/3.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h3>Features</h3>
                </header>

                <div className="row justify-content-center mt-3">

                    {temp.map((values) => {

                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">
                                <div className="icon"><i className="bi bi-briefcase" ></i></div>
                                <h4 className="title"><a>{values.description}</a></h4>
                                <p className="description">AI/ML Enabled automationAI/ML Enabled automationAI/ML Enabled automation</p>
                            </div>
                        </div>
                    })
                    }
                </div>
            </div>
        </section>

    )
}

export default SingleProductDeatils;
