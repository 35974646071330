import React, { useState, useEffect } from 'react'
import Products from '../services/ProductService'
import { createSearchParams, useNavigate } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';


function OurProduct() {

    const history = useNavigate();

    const [data, setData] = useState([]);

    const [content, setContent] = useState("")


    useEffect(() => {
        Products.OurProducts()
            .then(res => {
                setData(res.data)
                console.log(res.data)
            }, (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
                console.error(content);
            })
            .catch(err => (err))
    }, [])


    const onNavigate = (event) => {
        console.log(event)

        const foundElement = data.find(obj => {
            console.log(obj.id)
            return obj.id === event;
        })

        console.log(foundElement)

        history({
            pathname: `/#/product#product${event}`,
        })

    }



    return (
        <section id="products" className="products">
            <div className="container p-3">
                {/* 
                <div className="section-title text-center our-product-header " data-aos="fade-up">
                    <div className="our-product-header ">
                        <h2>Our Products</h2>
                    </div>
                </div> */}
                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Our Products</span>
                </div><br />


                <div className="row gy-4">

                    {data.map(product => (

                        <div key={product.id} className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 ">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className='text-center'>
                                    <img src={"./images/" + product.iconUrl} alt='...' width="100" height="100" className='img-fluid' />
                                </div>
                                <h3 className="title justify-text text-center" style={{ wordBreak: 'break-word' }}><a>{product.name}</a></h3>
                                <ul className='justified-list product-card-content'>
                                    {product.FeatureLists.map((obj) => {


                                        // return (obj.featureList.map((obj) => {

                                        return (<li className='product-card-list'>{obj.description}</li>)
                                        // }
                                        // ))
                                    })
                                    }
                                </ul>
                                {/* <button className="btn btn-primary " onClick={() => onNavigate(product.id)}>Read more </button> */}

                                <div className='text-center '>
                                    <button type="button" className="btn btn-primary prod-btn " ><NavHashLink className="navhash" smooth
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={`/product#product${product.id}`}>Read More</NavHashLink></button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default OurProduct;