import React, { useState } from 'react';
import { useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    const reloadButton = () => {
        window.location.reload();
    };

    const handleOnError = (error, info) => {
        // It will catch errors in any component below. You can also log the error to an error reporting service.
        // logErrorToMyService(error, info);
        setHasError(true);
    };

    useEffect(() => {
        // Add an event listener to monitor changes in online/offline status
        const handleOnlineStatusChange = () => {
            setIsOnline(window.navigator.onLine);
        };

        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOnlineStatusChange);

        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
        };
    }, []);

    return (
        <div>
            {!isOnline ? <div className="text-center justify-content-center">
                <img src="./images/page-not-found-image.jpg" alt="Image" />
                <p className="justify-content-center mt-2 fs-3">Server Not found</p>
                <button
                    className="btn btn-primary w-25 m-2 text-center mr-2 mt-4"
                    onClick={reloadButton}
                >
                    Refresh Page
                </button>
            </div> : <>{hasError ? (
                <div className="text-center justify-content-center">
                    <img src="./images/error-image.jpg" alt="Image" />
                    <p className="justify-content-center mt-2 fs-3">
                        Oops, Something went wrong. Please try again later
                    </p>
                    <button
                        className="btn btn-primary w-25 m-2 text-center mr-2 mt-4"
                        onClick={reloadButton}
                    >
                        Refresh Page
                    </button>
                </div>) : (
                <ErrorBoundaryComponent onError={handleOnError}>
                    {children}
                </ErrorBoundaryComponent>
            )}</>}
        </div>
    );
};

const ErrorBoundaryComponent = ({ onError, children }) => {
    React.useEffect(() => {
        const handleWindowError = (errorEvent) => {
            onError(errorEvent.error);
        };

        window.addEventListener('error', handleWindowError);

        return () => {
            window.removeEventListener('error', handleWindowError);
        };
    }, [onError]);

    return <>{children}</>;
};

export default ErrorBoundary;
