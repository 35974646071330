import React, { useEffect, useState } from "react";
import InquiryService from "../services/InquiryService";
import organizationDetails from "../services/organizationDetails";
import Products from "../services/OurProducts";
import OurServices from "../services/OurServices";
// import Recaptcha from 'react-google-invisible-recaptcha'
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from 'react-bootstrap';


function ConnectUs() {


    const key = '6LcBVdMoAAAAAPIzPtUSa16xXXeWAleCHKYnEtQP'

    const captcharef = useRef(null)

    const [captchaKey, setCaptchaKey] = useState('')

    const [showMessage, setShowMesaage] = useState(false)

    const [content, setContent] = useState("");

    const [buttonText, setButtonText] = useState('Submit');

    const [isCaptcha, setIsCaptcha] = useState(false)

    const [location, setlocation] = useState()

    const [email, setEmail] = useState()

    const [contact, setcontact] = useState()

    const [hours, setHours] = useState()

    // const [subject, setSubject] = useState([])

    const [service] = useState([])

    const [product] = useState([])

    const [status, setStatus] = useState(false)

    const [servicedata, setservicedata] = useState([])

    const [productdata, setProductdata] = useState([])

    const [fullNameErr,setFullNameErr]=useState('')

    const [emailErr,setEmailErr]=useState('')

    const [contactErr,setContactErr]=useState('')

    const [msgErr,setMsgErr]=useState('')


    useEffect(() => {
        organizationDetails.organizationContactDetails()
            .then((response) => {
                response.data.map((orgdata) => {
                    setlocation(orgdata.location);
                    setEmail(orgdata.email);
                    setcontact(orgdata.contactNo);
                    setHours(orgdata.openHours);
                })

            }
                ,
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content);

                }).catch((error) => {
                    console.log(error);
                })
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        OurServices.getAllServices()
            .then((response) => {
                console.log(response.data)

                setservicedata(response.data)

                // response.data.map((obj) => {
                //     console.log(obj.name)

                //     if (!service.includes(obj.name)) {
                //         console.log(obj.name)
                //         service.push(obj.name)
                //     }
                // })

            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content);

                }).catch((error) => {
                    console.log(error)
                })

        Products.getAllProducts()
            .then((response) => {
                console.log(response.data)

                setProductdata(response.data)

                // response.data.map((obj) => {
                //     console.log(obj.name)
                //     if (!product.includes(obj.name)) {
                //         console.log(obj.name)
                //         product.push(obj.name)
                //     }
                // })

            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    console.error(content);

                }).catch((error) => {
                    console.log(error)
                })

    }, [])

    console.log(product)

    console.log(service)

    const [user, setuser] = useState({
        fullName: "",
        email: "",
        subject: "",
        message: "",
        contactNo: ""
    })

    const onDataChange = e => {
        console.log(e.target.value);
        setuser({ ...user, [e.target.name]: e.target.value });
        if (user.email && user.email.trim() !== '') {
            if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.email)) {
                setEmailErr("Please Enter Valid Email");
            }
            else{
                setEmailErr('')
            }
            

        }
        

        if (user.contactNo && user.contactNo.trim() !== '') {
            if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(user.contactNo)) {
                setContactErr("Please Enter Your Valid Contact No");
            }
            else{
                setContactErr('')
            }

        }

        if (user.fullName && user.fullName.trim() !== '') {
            if (!/^[A-Za-z\s]*$/.test(user.fullName)) {
                setFullNameErr("Please Enter Your Valid Full Name");
            }
            else{
                setFullNameErr('')
            }

        }
        
    };


    function onChange(value) {
        // console.log("Captcha value:", value);
        setIsCaptcha(true)
        setCaptchaKey(value)
    }

    const OnSubmit = e => {
        e.preventDefault();

        captcharef.current.reset()
        setButtonText('Loading...');
        if (captchaKey !== '') {

            InquiryService.inquiryService(user)
                .then((response) => {
                    console.log(response.status);
                    if (response.status === 200) {

                        setShowMesaage(true)
                        const timeoutvar = setTimeout(() => {
                            setShowMesaage(false);
                            // setButtonText('Message Send Successfully');
                        }, 3500);
                        return () => clearTimeout(timeoutvar)
                    } else {
                        setButtonText("Something went Wrong")
                    }
                }).catch((error) => {
                    console.log(error)
                })
        }

        // if (user.email && user.email.trim() !== '') {
        //     if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.email)) {
        //         setEmailErr("Please Enter Valid Email");
        //     }

        // }

        // if (user.contactNo && user.contactNo.trim() !== '') {
        //     if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(user.contactNo)) {
        //         setContactErr("Please Enter Your Valid Contact No");
        //     }

        // }

        // if (user.fullName && user.fullName.trim() !== '') {
        //     if (!/^[A-Za-z\s]*$/.test(user.fullName)) {
        //         setFullNameErr("Please Enter Your Valid Full Name");
        //     }

        // }

        setuser({
            fullName: "",
            email: "",
            subject: "",
            message: "",
            contactNo: ""
        })
        setButtonText("Send Message")
    }

    const onResolved = (token) => {
        // alert('Recaptcha resolved with response: ' + recaptcha.current.getResponse());
        setStatus(true)
        console.log(token)
    }

    const handleClose=()=>{
        setShowMesaage(false)
        setuser({
            fullName: "",
            email: "",
            subject: "",
            message: "",
            contactNo: ""
        })
        
    }


    // function CombineLabel() {
    //     console.log("label combined")
    //     return <>
    //         <label className="description">Name</label><label className="placeholder-red">*</label></>
    // }





    return (


        <section id="contact" className="contact">

            <div className="container p-4" data-aos="fade-up">
                {/* <div className="section-header">
                    <h2>Contact Us</h2>
                </div> */}

                <div className='section-title text-center'>
                    <span className='our-product-header text-center'>Contact Us</span>
                </div><br />

                <div className="row gx-lg-0 gy-4 mb-1 justify-content-center">


                    {/* <div className="col-lg-4">
                         <div className="info-container d-flex flex-column align-items-center justify-content-center">
                            <div className="info-item d-flex">
                                <i className="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h4>Location:</h4>
                                    <p>{location}</p>
                                </div>
                            </div>

                            <div className="info-item d-flex">
                                <i className="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h4>Email:</h4>
                                    <p>{email}</p>
                                </div>
                            </div>

                            <div className="info-item d-flex">
                                <i className="bi bi-phone flex-shrink-0"></i>
                                <div>
                                    <h4>Call:</h4>
                                    <p>{contact}</p>
                                </div>
                            </div>

                            <div className="info-item d-flex">
                                <i className="bi bi-clock flex-shrink-0"></i>
                                <div>
                                    <h4>Open Hours:</h4>
                                    <p>{hours}</p>
                                </div>
                            </div>
                        </div>
                    </div>*/}

                    <div className="col-lg-8 mb-2">
                        <form onSubmit={OnSubmit} role="form" className="connect-with-us-form">
                            <div className="row">
                                <div className="col-md-6 form-group ">
                                    <span >
                                        <label className="description">Name <span className='placeholder-red'>*</span></label>
                                    </span>
                                    <input type="text" name="fullName" className="form-control" id="fullName" placeholder="Enter Your Name" value={user.fullName} onChange={onDataChange} required />
                                    {fullNameErr ? <p className='error-msg font-fam-for-all'>{fullNameErr}</p> : ''}
                                </div>
                                
                                <div className="col-md-6 form-group mt-md-0">
                                    <span >
                                        <label className="description">Email <span className='placeholder-red'>*</span></label>
                                    </span>
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Enter your Email" value={user.email} onChange={onDataChange} required />
                                    {emailErr ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ''}
                                </div>
                                
                                <div className="col-md-6 form-group  mt-2">
                                    <span >
                                        <label className="description">Contact No <span className='placeholder-red'>*</span> </label>
                                    </span>
                                    <input type="tel" className="form-control" name="contactNo" id="contactNo" placeholder="Enter your Contact Number" value={user.contactNo} onChange={onDataChange} />
                                    {contactErr ? <p className='error-msg font-fam-for-all'>{contactErr}</p> : ''}
                                </div>
                                
                                <div className="col-md-6 form-group mt-2">
                                    <span >
                                        <label className="description">Subject <label className="placeholder-red">*</label></label>
                                    </span>
                                    <select type="text" className="form-select" placeholder="Subject" name="subject" id="subject" value={user.subject} onChange={onDataChange} required >

                                        <option selected className="description">Subject</option>
                                        <option disabled className="dropdown-title">Products</option>
                                        {productdata.map((obj) =>
                                            (<option value={obj.name} className="menu-item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{obj.name}</option>)
                                        )}

                                        <option disabled className="dropdown-title">Services</option>
                                        {servicedata.map((obj) =>
                                            (<option value={obj.name}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{obj.name}</option>)
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <label className="description">Message <span className='placeholder-red'>*</span></label>
                                <textarea className="form-control" name="message" rows="7" placeholder="Enter Your Message" value={user.message} onChange={onDataChange} ></textarea>
                                {msgErr ? <p className='error-msg font-fam-for-all'>{msgErr}</p> : ''}
                            </div>
                           
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="contact-captcha">
                                <ReCAPTCHA
                                    sitekey={key}
                                    onChange={onChange}
                                    ref={captcharef}
                                    onExpired={e => setCaptchaKey('')}
                                />
                            </div>

                            <div className="text-center">
                                <button type="submit" disabled={user.contactNo && user.email && user.fullName && user.message ? false : true}
                                    className="btn  btn-primary"  data-bs-dismiss="modal" data-bs-target="#staticBackdrop" data-bs-toggle="modal" >Submit</button>
                            </div>
                        

                            <div className=" align-items-center ">
                                <div className="row mt-2 ">
                                    <div className="col-md-6 justify-content-center">
                                        <div className="info-item d-flex ">
                                            <i className="bi bi-geo-alt flex-shrink-0"></i>
                                            <div>
                                                <h4>Location:</h4>
                                                <p>{location}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 justify-content-center">
                                        <div className="info-item d-flex">
                                            <i className="bi bi-envelope flex-shrink-0"></i>
                                            <div>
                                                <h4>Email:</h4>
                                                <p>{email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6 justify-content-center">
                                        <div className="info-item d-flex">
                                            <i className="bi bi-phone flex-shrink-0"></i>
                                            <div>
                                                <h4>Contact:</h4>
                                                <p style={{width:'150px',wordWrap:'break-word'}}>{contact}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 justify-content-center">
                                        <div className="info-item d-flex">
                                            <i className="bi bi-clock flex-shrink-0"></i>
                                            <div>
                                                <h4>Hours:</h4>
                                                <p>{hours}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal fade  dialog-box" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" show={showMessage}>
                        <div className="modal-dialog ">
                            <div className="modal-content ">
                                <div className="modal-body text-center">
                                    <img src='../images/Success-Toast-image.png' width='100' height='100' />
                                    <div className="text-center">
                                        <a className="description">Details Successfully Submitted</a>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default ConnectUs;