import axios from "axios";



const getAllServices = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/service/get")
    } catch (error) {

    }
}

export default { getAllServices, }


