import axios from "axios";

const getAllProducts = () => {
    try {
        return axios.get("https://www.api.redberyltech.com/api/product/get")
    } catch (error) {
        return [];
    }
}

export default {
    getAllProducts,
}