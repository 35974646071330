import axios from "axios";



    const aboutusService=()=>{
        try {
            return axios.get("https://www.api.redberyltech.com/api/organization/get");   
        } catch (error) {
            return[];
        }
    }


export default { 
    aboutusService,
}