import React from 'react'
import ServiceInfo from './ServiceInfo';
import ConnectUs from './ConnectUs';
import Footer from './Footer';


function ServicePage() {
  return (
    <>
    <div className='container service-page'>
      <ServiceInfo />
      {/* <ConnectUs /> */}
    </div>
     <Footer />
    </>
  )
}

export default ServicePage